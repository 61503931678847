import {
    EquipmentHeating,
    EquipmentValue,
    RealEstateCategory,
    RealEstateCondition,
    RealEstateConstruction,
    RealEstateUse
} from "@onpreo/database/src/definitions/real-estate-helpers";

/* -------------------------------------------------------------------------- */
/*                                Rules Helpers                               */
/* -------------------------------------------------------------------------- */

export function propertyType(type: string): RealEstateConstruction {
    switch (type) {
        // DEPRECATED
        // case "Einfamilienhaus":
        //     return RealEstateConstruction.EINFAMILIENHAUS;
        case "Freistehend":
            return RealEstateConstruction.FREISTEHEND;
        case "Doppelhaushälfte":
            return RealEstateConstruction.DOPPELHAUS;
        case "Reihenhaus":
            return RealEstateConstruction.REIHENHAUS;
        case "Bungalow":
            return RealEstateConstruction.BUNGALOW;
        case "Villa":
            return RealEstateConstruction.VILLA;
        case "Bauernhaus":
            return RealEstateConstruction.FARMHOUSE;
        case "Reihenmittlehaus":
            return RealEstateConstruction.REIHEN_MITTELHAUS;
        case "Reihenendhaus":
            return RealEstateConstruction.REIHEN_ENDHAUS;
    }
}

export function houseStatus(status: string): RealEstateCondition {
    if (status === "Renoviert") return RealEstateCondition.RENOVATED;
    else if (status === "Gepflegt") return RealEstateCondition.MAINTAINED;
    else if (status === "Saniert") return RealEstateCondition.REFURBISHED;
    else if (status === "Neu") return RealEstateCondition.NEW;
    else if (status === "Sanierungsbedürftig") return RealEstateCondition.TO_BE_RENOVATED;
    else if (status === "Renovierungsbedürftig") return RealEstateCondition.NEED_TO_BE_RENOVATED;
    else return;
}

export function houseCondition(condition: RealEstateCondition): string {
    if (condition === RealEstateCondition.RENOVATED) return "Renoviert";
    else if (condition === RealEstateCondition.MAINTAINED) return "Gepflegt";
    else if (condition === RealEstateCondition.REFURBISHED) return "Saniert";
    else if (condition === RealEstateCondition.NEW) return "Neu";
    else if (condition === RealEstateCondition.TO_BE_RENOVATED) return "Sanierungsbedürftig";
    else if (condition === RealEstateCondition.NEED_TO_BE_RENOVATED) return "Renovierungsbedürftig";
    else return;
}

export function propertyUse(type: string): RealEstateUse {
    switch (type) {
        case "Vermietet":
            return RealEstateUse.VERMIETET;
        case "(teilweise) Vermietet":
            return RealEstateUse.TEILWEISE_VERMIETET;
        case "Selbst bewohnt":
            return RealEstateUse.SELBST_BEWOHNT;
        case "Leerstand":
            return RealEstateUse.LEERSTAND;
        case "komplett Vermietet":
            return RealEstateUse.KOMPLETT_VERMIETET;
    }
}

export function getBasement(hasKeller: string): boolean {
    if (hasKeller === "Ja") return true;
    else if (hasKeller === "Nein") return false;
    else return;
}

export function getParking(hasParkPlatz: string): boolean {
    if (hasParkPlatz === "Ja") return true;
    else if (hasParkPlatz === "Nein") return false;
    else return;
}

export function getBasementValue(value: boolean): string {
    if (value === true) return "Ja";
    else if (value === false) return "Nein";
    else return;
}

export function getParkingValue(value: boolean): string {
    if (value === true) return "Ja";
    else if (value === false) return "Nein";
    else return;
}

export function apartmentConstruction(type: RealEstateConstruction): string {
    switch (type) {
        case RealEstateConstruction.DACHGESCHOSS:
            return "Dachgeschoss";
        case RealEstateConstruction.ETAGENWOHNUNG:
            return "Etagenwohnung";
        case RealEstateConstruction.LOFT:
            return "Loft";
        case RealEstateConstruction.MAISONETTE:
            return "Maisonette";
        case RealEstateConstruction.SOUTERRAIN:
            return "Souterrain";
        case RealEstateConstruction.PENTHOUSE:
            return "Penthouse";
        case RealEstateConstruction.HOCHPARTERRE:
            return "Hochparterre";
        case RealEstateConstruction.ERDGESCHOSSWOHNUNG:
            return "Erdgeschosswohnung";
        case RealEstateConstruction.TERRASSENWOHNUNG:
            return "Terrassenwohnung";
        case RealEstateConstruction.DACHGESCHOSSPENTHOUSE:
            return "Dachgeschosspenthouse";
        case RealEstateConstruction.MITGARTENTEIL:
            return "Mitgartenteil";
        default:
            return;
    }
}

export function apartmentConstructionFromArt(type: string): RealEstateConstruction {
    switch (type) {
        case "Dachgeschoss":
            return RealEstateConstruction.DACHGESCHOSS;
        case "Etagenwohnung":
            return RealEstateConstruction.ETAGENWOHNUNG;
        case "Loft":
            return RealEstateConstruction.LOFT;
        case "Maisonette":
            return RealEstateConstruction.MAISONETTE;
        case "Souterrain":
            return RealEstateConstruction.SOUTERRAIN;
        case "Penthouse":
            return RealEstateConstruction.PENTHOUSE;
        case "Hochparterre":
            return RealEstateConstruction.HOCHPARTERRE;
        case "Erdgeschosswohnung":
            return RealEstateConstruction.ERDGESCHOSSWOHNUNG;
        case "Terrassenwohnung":
            return RealEstateConstruction.TERRASSENWOHNUNG;
        case "Dachgeschosspenthouse":
            return RealEstateConstruction.DACHGESCHOSSPENTHOUSE;
        case "Mitgartenteil":
            return RealEstateConstruction.MITGARTENTEIL;
        default:
            return;
    }
}

export function elevatorString(elevator: boolean | undefined): string {
    if (elevator === true) return "mit Aufzug";
    else if (elevator === false) return "ohne Aufzug";
    else return;
}

export function getEquipmentHeatingType(type: string): EquipmentHeating {
    switch (type) {
        case "Fußbodenheizung":
            return EquipmentHeating.FUSSBODENHEIZUNG;
        case "Einzelofen":
            return EquipmentHeating.EINZELOEFEN;
        case "Zentralheizung":
            return EquipmentHeating.ZENTRALHEIZUNG;
        case "Sonstige":
            return EquipmentHeating.SONSTIGE;
        default:
            return;
    }
}

export function getHeatingTypeFromEquipment(type: EquipmentHeating): string {
    switch (type) {
        case EquipmentHeating.FUSSBODENHEIZUNG:
            return "Fußbodenheizung";
        case EquipmentHeating.EINZELOEFEN:
            return "Einzelofen";
        case EquipmentHeating.ZENTRALHEIZUNG:
            return "Zentralheizung";
        case EquipmentHeating.SONSTIGE:
            return "Sonstige";
        default:
            return;
    }
}

export function cardMonthChangeWithSliderValue(type: number): string {
    if (type === 80) return "1-3";
    else if (type === 90) return "2-4";
    else if (90 < type && type < 100) return "3-5";
    else if (type === 100) return "4-6";
    else if (100 < type && type < 110) return "6-9";
    else if (type === 110) return "9-12";
    else if (type === 120) return "12-24";
}

export function cardBuyersWithSliderValue(type: number, buys: number[]): string {
    if (type >= 80 && type < 90) return buys[4].toString();
    else if (type >= 90 && type < 100) return buys[3].toString();
    else if (type >= 100 && type < 110) return buys[2].toString();
    else if (type >= 110 && type < 120) return buys[1].toString();
    else return buys[0].toString();
}

export function cardTextChangeWithSliderValue(type: number): string {
    if (type >= 80 && type < 90) return "Hoch";
    else if (type >= 90 && type < 100) return "Gut";
    else if (type >= 100 && type < 110) return "Moderat";
    else if (type >= 110 && type < 120) return "Gering";
    else return "Sehr gering";
}

export function getRealEstateCategory(type: string): RealEstateCategory {
    switch (type) {
        case "haus":
            return RealEstateCategory.EFH;
        case "wohnung":
            return RealEstateCategory.ETW;
        case "mehrfamilienhaus":
            return RealEstateCategory.MFH;
    }
}

export function getEquipmentType(value: EquipmentValue): string {
    switch (value) {
        case EquipmentValue.EINFACH:
            return "Einfach";
        case EquipmentValue.EINFACH_MITTEL:
            return "Einfach Mittel";
        case EquipmentValue.GEHOBEN:
            return "Gehoben";
        case EquipmentValue.GEHOBEN_STARK_GEHOBEN:
            return "Gehoben Stark Gehoben";
        case EquipmentValue.MITTEL:
            return "Mittel";
        case EquipmentValue.MITTEL_GEHOBEN:
            return "Mittel Gehoben";
        case EquipmentValue.STARK_GEHOBEN:
            return "Stark Gehoben";
    }
}

export function getValueFromEquipment(equipment: string): EquipmentValue {
    switch (equipment) {
        case "Einfach":
            return EquipmentValue.EINFACH;
        case "Einfach Mittel":
            return EquipmentValue.EINFACH_MITTEL;
        case "Gehoben":
            return EquipmentValue.GEHOBEN;
        case "Gehoben Stark Gehoben":
            return EquipmentValue.GEHOBEN_STARK_GEHOBEN;
        case "Mittel":
            return EquipmentValue.MITTEL;
        case "Mittel Gehoben":
            return EquipmentValue.MITTEL_GEHOBEN;
        case "Stark Gehoben":
            return EquipmentValue.STARK_GEHOBEN;
    }
}

export function getRealEstateCategoryMap(type: RealEstateCategory): string {
    switch (type) {
        case RealEstateCategory.EFH:
            return "Haus";
        case RealEstateCategory.ETW:
            return "Wohnung";
        case RealEstateCategory.MFH:
            return "Mehrfamilienhaus";
    }
}

const pfRound = (num: number) => Math.round(Math.round(num) / 10) * 10;

/* -------------------------------------------------------------------------- */
/*                                Pricing Rules                               */
/* -------------------------------------------------------------------------- */

// use linear Interpolation to connect Points
export function linearInterpolation(x_lower: number, y_lower: number, x_upper: number, y_upper: number, x: number): number {
    if (x_lower === x_upper) {
        throw new Error("x_lower and x_upper can't be equal.");
    }

    const y = y_lower + ((x - x_lower) * (y_upper - y_lower)) / (x_upper - x_lower);

    return y;
}

/**
 * Calculates the plot area value change depending on the initial plot area provided, the changed plot area
 * and the estimate provided by our data source (PriceHubble or Sprengnetter).
 *
 * The base change is 0, as the data source will already have taken into account the change. Therefore, we are only
 * relatively changing the price per sqm depending on the estimate and in relation to the price per sqm living area.
 *
 * We are using a sliding window approach here, always depending on the initial values.
 *
 * The problem in this calculation is the landValueFactor, which is currently a "magic number". The improvement to this
 * model would be an estimation for every part of a city, to actually know the change and know what the data source
 * used in the first place.
 *
 * According to ImmoScout24, the plot area actually does NOT influence the price of the real estate, as a marginal
 * difference of two comparable real estates will not be influenced.
 *
 * In Homeday, however, it is influenced by the Bundesland sqm price, which is a super rough estimate.
 *
 * Therefore, the resulting current solution should at least be better than homeday, as it is taking the dependant value
 * into account, which is location specific on a city level instead of Bundesland.
 *
 * Sources:
 * - https://www.immobilienscout24.de/immobilienbewertung/ratgeber/wissen/zuschlaege-abschlaege-hauswert.html
 * - https://www.homeday.de/de/immobilienbewertung/grundstueckspreise/
 *
 * @param initPlotArea  The given plot area to the data source to create the first estimate
 * @param landArea  The changed new plot area by the real estate agent or owner
 * @param estimate  The returned estimate from the price data source
 * @returns
 */
export function priceChangeWithPlotArea(initPlotArea: number, landArea: number, estimate: number): number {
    const landValueFactor = 0.1;
    return pfRound((landArea - initPlotArea) * (estimate / initPlotArea) * landValueFactor);
}

const houseConstructionFactor = {
    Freistehend: 0.018,
    Doppelhaushälfte: 0.011,
    Reihenhaus: 0, // don't change for this
    Bungalow: 0.009,
    Villa: 0.0295,
    Bauernhaus: -0.0085,
    // NOTE:  source for these 2 are TOBI
    Reihenendhaus: 0.008,
    Reihenmittlehaus: 0.01
};
/**
 * This function calculates the price change of a house based on its type and estimated
 * value.
 * @param {string} type - The type parameter is a string that represents the type of house
 * construction. It is used to determine the appropriate construction factor to apply to the estimate
 * parameter.
 * @param {number} estimate - The estimated value of a house.
 * @returns a number, which is the estimated price change based on the type of house and the original
 * estimate provided as input. The estimated price change is calculated by multiplying the original
 * estimate by a factor specific to the type of house, which is retrieved from the
 * `houseConstructionFactor` object. If the type of house is not found in the `houseConstructionFactor`
 * object, the function returns a price change of 0
 */
export function priceChangeWithHouseType(type: string, estimate: number): number {
    // https://www.immobilienscout24.de/immobilienbewertung/ratgeber/wissen/zuschlaege-abschlaege-hauswert.html
    if (!Object.keys(houseConstructionFactor).includes(type)) return 0;
    return pfRound(estimate * houseConstructionFactor[type]);
}

/**
 * The year influences the price quite heavily, according to Aamir's and Luca's Master Thesis. It is one of the
 * main principle components used to calculate the price.
 *
 * As the year is already part of the initial estimate given by our data source (PriceHubble or Sprengnetter)
 * it is already taken into account. Hence, a change of zero in the given year will give a change of zero in the
 * given price.
 *
 * To account for a reasonable change in price, we adjusted the rest value formular from ImmoScout24 to a sliding
 * window function dependent on the initial year, the new year and the estimate from the data source.
 *
 * The estimate already includes the age depreciation, so we have to take this into account when calculating the
 * price change in the sliding window.
 *
 * Sources:
 * - https://www.immobilienscout24.de/wissen/verkaufen/altersabschlag.html
 *
 * @param initYear  The given year to the data source for the initial estimate
 * @param year  The changed year by the real estate agent or owner
 * @param estimate  The returned estimate from the price data source
 * @returns
 */
export function priceChangeWithInitYear(initYear: number, year: number, estimate: number): number {
    const MEAN_LIFE = 80;
    const age = initYear - year;
    const ageOffsetOnpreoFactor = 0.13 + 0.003 * Math.abs(age);

    const ageDepreciation = (age / MEAN_LIFE) * 100 * ageOffsetOnpreoFactor;
    // the estimate already includes the age depreciation, so we have to take this into account when calculating the price change
    const adustedAgeDepreciation = ageDepreciation / (100 - ageDepreciation);
    return -pfRound(estimate * adustedAgeDepreciation);
}

// https://www.immobilienscout24.de/wissen/verkaufen/altersabschlag.html
export function priceChangeWithYear(constructionYear: number, renovationYear: number, estimate: number): number {
    const MAX_DEPRECIATION_YEARS = 30;
    const currentDate = new Date().getFullYear();
    const renovationAge = currentDate - renovationYear;
    let estimateFactor;

    if (estimate <= 350000) estimateFactor = 0.075;
    else if (estimate > 350000 && estimate < 750000) estimateFactor = 0.05;
    else if (estimate > 750000 && estimate < 1000000) estimateFactor = 0.035;
    else estimateFactor = 0.025;

    // for renovationAge 0
    const MAX_VALUE = estimate * estimateFactor;
    // for renovationAge 30
    const MIN_VALUE = 0;

    if (renovationAge >= MAX_DEPRECIATION_YEARS || renovationAge < 0) return MIN_VALUE;

    const ageDepreciation = ((MAX_DEPRECIATION_YEARS - renovationAge) / MAX_DEPRECIATION_YEARS) * MAX_VALUE;
    return pfRound(ageDepreciation);
}

const realEstateUsageFactor = {
    "(teilweise) Vermietet": -0.03,
    Vermietet: -0.05,
    Leerstand: 0.021,
    "Selbst bewohnt": 0.015,
    "komplett Vermietet": -0.06
};

/**
 * This function calculates the price change of a real estate property based on its usage
 * type and estimated value.
 * @param {string} useType - a string representing the type of real estate usage (e.g. vermietet, leerstand)
 * @param {number} estimate - The estimated price of a real estate property.
 * @returns a number, which is the result of multiplying the `estimate` parameter by the value of the
 * `realEstateUsageFactor` object at the key specified by the `useType` parameter. If the `useType`
 * parameter is not a key in the `realEstateUsageFactor` object, the function returns 0.
 */
export function priceChangeWithUse(useType: string, estimate: number): number {
    if (!Object.keys(realEstateUsageFactor).includes(useType)) return 0;
    return pfRound(estimate * realEstateUsageFactor[useType]);
}

/**
 * This function calculates the price change based on whether a property has a basement or
 * not.
 *
 * https://www.zuhause3.de/haus-und-bau/keller-mehr-platz-und-mehr-wert#:~:text=Generell%20gilt%20die%20Faustregel%3A%205,sich%20ein%20Keller%20fast%20immer.
 *
 * @param {string} hasKeller - The parameter "hasKeller" is a string that indicates whether the
 * property being evaluated has a basement or not. It can have two possible values: "Ja" (which means
 * "Yes" in German) if the property has a basement, or "Nein" (which means "No"
 * @param {number} estimate - The estimated price of a property.
 * @returns a number which represents the price change based on whether the property has a basement or
 * not. If the property has a basement, the function returns 0. If the property does not have a
 * basement, the function returns a negative value which is 5% of the estimate. If the input for
 * hasKeller is not "Ja" or "Nein", the function returns undefined.
 */
export function priceChangeWithBasement(hasKeller: string, estimate: number): number {
    if (hasKeller === "Ja") return pfRound(estimate * 0.006);
    else if (hasKeller === "Nein") return -pfRound(estimate * 0.007);
    else return;
}

// export function priceChangeWithParking(hasParkPlatz: string): number {
//     if (hasParkPlatz === "Ja") return 9150;
//     else if (hasParkPlatz === "Nein") return -4530;
//     else return;
// }

export function priceChangeWithParking(hasParkPlatz: string, cardValue: number, watchFields?: any): number {
    switch (hasParkPlatz) {
        case "Ja":
            let priceChange = 0;
            if (watchFields)
                watchFields.forEach(item => {
                    let temp = 0;
                    if (item["parkingPosition"] === "private" && item["parkingType"] === "OUTSIDE") {
                        temp = Math.round(9150 - cardValue * 0.0025);
                        priceChange = priceChange + (temp > 6000 ? temp : 6000);
                    } else if (item["parkingPosition"] === "private" && item["parkingType"] === "UNDERGROUND_GARAGE") {
                        temp = Math.round(9150 + cardValue * 0.005);
                        priceChange = priceChange + (temp < 15000 ? temp : 15000);
                    } else if (item["parkingPosition"] === "private" && item["parkingType"] === "GARAGE") {
                        temp = Math.round(9150 + cardValue * 0.0045);
                        priceChange = priceChange + (temp < 14000 ? temp : 14000);
                    } else if (item["parkingPosition"] === "private" && item["parkingType"] === "CARPORT") {
                        temp = Math.round(9150 + cardValue * 0.0015);
                        priceChange = priceChange + (temp < 11000 ? temp : 11000);
                    } else if (item["parkingPosition"] === "private" && item["parkingType"] === "DUPLEX") {
                        temp = Math.round(9150 + cardValue * 0.002);
                        priceChange = priceChange + (temp < 15500 ? temp : 15500);
                    } else if (item["parkingPosition"] === "private" && item["parkingType"] === "CAR_PARK") {
                        temp = Math.round(9150 + cardValue * 0.0015);
                        priceChange = priceChange + (temp < 10000 ? temp : 10000);
                    } else if (item["parkingPosition"] === "public" && item["parkingType"] === "OUTSIDE") {
                        temp = Math.round(9150 - cardValue * 0.005);
                        priceChange = priceChange + (temp > 5000 ? temp : 5000);
                    } else if (item["parkingPosition"] === "public" && item["parkingType"] === "UNDERGROUND_GARAGE") {
                        temp = Math.round(9150 + cardValue * 0.0025);
                        priceChange = priceChange + (temp < 12000 ? temp : 12000);
                    } else if (item["parkingPosition"] === "public" && item["parkingType"] === "GARAGE") {
                        temp = Math.round(9150 + cardValue * 0.002);
                        priceChange = priceChange + (temp < 11000 ? temp : 11000);
                    } else if (item["parkingPosition"] === "public" && item["parkingType"] === "CARPORT") {
                        temp = Math.round(9150 + cardValue * 0.001);
                        priceChange = priceChange + (temp < 10500 ? temp : 10500);
                    } else if (item["parkingPosition"] === "public" && item["parkingType"] === "DUPLEX") {
                        temp = Math.round(9150 + cardValue * 0.002);
                        priceChange = priceChange + (temp < 11500 ? temp : 11500);
                    } else if (item["parkingPosition"] === "public" && item["parkingType"] === "CAR_PARK") {
                        temp = Math.round(9150 + cardValue * 0.0015);
                        priceChange = priceChange + (temp < 9500 ? temp : 9500);
                    }
                });
            return priceChange;
        case "Nein":
            let change = Math.round(-4000 - 0.003 * cardValue);
            return change > -5000 ? change : -5000;
        default:
            return;
    }
}

/**
 * This function calculates the price change based on whether a property has a garden or
 * not.
 *
 * https://www.hausgold.de/immobilienwert-steigern/wertsteigerung-garten/#:~:text=Sp%C3%A4testens%20wenn%20Eigent%C3%BCmer%20ihr%20Haus,der%20deutschen%20Husqvarna%2DTochter%20Gardena.
 *
 * we are using a slightly toned down version of the above link, by dividing the price change in halt to make it less extreme.
 * Additionally we split the price change to be halt positive half negative for the sake of the price finder.
 *
 * @param {boolean} hasGarten - A boolean value indicating whether the property has a garden or not.
 * @param {number} estimate - The estimate parameter is a number that represents the estimated price of
 * a property.
 * @returns If `hasGarten` is `true`, the function returns 0 (we assume a house with garden to be baseline in a buyer's mind). If `hasGarten` is `false`, the function
 * returns -estimate * the mean value of price change in germany which is 18% (as baseline is 0, we reduce by estimated price drop).
 * If `hasGarten` is neither `true` nor `false`, the function returns `undefined`.
 */
export function priceChangeWithGarden(hasGarten: boolean, estimate: number, category: RealEstateCategory): number {
    const categoryFactor = category === RealEstateCategory.EFH ? 0.5 : 0.2;
    if (hasGarten === true) return pfRound(estimate * 0.02 * categoryFactor);
    else if (hasGarten === false) return -pfRound(estimate * 0.025 * categoryFactor);
    else return;
}

export function priceChangeWithBalcony(hasBalkon: boolean): number {
    if (hasBalkon === true) return 2370;
    else if (hasBalkon === false) return -4320;
    else return;
}

export function priceChangeWithTerasse(hasTerasse: boolean): number {
    if (hasTerasse === true) return 1740;
    else if (hasTerasse === false) return -2630;
    else return;
}

const noPriceTypes = ["Terrassenwohnung", "Mitgartenteil"];
export function priceChangeWithArtDerWohnung(type: string, lift: string): number {
    if (noPriceTypes.includes(type)) return 0;
    else if (type === "Etagenwohnung") {
        switch (lift) {
            case "mit Aufzug":
                return 4890;
            case "ohne Aufzug":
                return -6994;
        }
    } else if (type === "Dachgeschoss" || type === "Dachgeschosspenthouse") {
        switch (lift) {
            case "mit Aufzug":
                return 5427;
            case "ohne Aufzug":
                return -6994;
        }
    } else if (type === "Souterrain" || type === "Erdgeschosswohnung") {
        switch (lift) {
            case "mit Aufzug":
                return -3470;
            case "ohne Aufzug":
                return 0;
        }
    } else if (type === "Loft" || type === "Hochparterre" || type === "Maisonette") {
        switch (lift) {
            case "mit Aufzug":
                return 3470;
            case "ohne Aufzug":
                return 0;
        }
    } else if (type === "Penthouse") {
        switch (lift) {
            case "mit Aufzug":
                return 5427;
            case "ohne Aufzug":
                return -6994;
        }
    }
}

const equipmentStatusFactor = {
    Renoviert: 0.0075,
    Gepflegt: 0.005,
    Saniert: 0.01,
    Sanierungsbedürftig: -0.05,
    Renovierungsbedürftig: -0.025,
    Neu: 0.015
};

/**
 * This function calculates the price change based on the status of a property and its current value.
 * 
 * NOTE: below values reference: 
 * https://immobilien.vr.de/immobilien/baufinanzierung/baufinanzierungsthemen/renovieren-sanieren-modernisieren.html#:~:text=Wie%20unterscheiden%20sich%20Renovieren%2C%20Sanieren,Immobilie%20aufwerten%2C%20dann%20mit%20Modernisierungsma%C3%9Fnahmen

 * 
* @param {string} status - a string representing the condition of a property (e.g. "Renoviert",
 * "Gepflegt", "Saniert", "Sanierungsbedürftig", "Neu").
 * @param {number} estimate - The value of a property or real estate asset.
 * @returns a number that represents the price change based on the status of a property. The value
 * returned is calculated by multiplying the estimate parameter by a certain percentage, depending on
 * the status.
 */
export function priceChangeWithStatus(status: string, estimate: number) {
    if (!Object.keys(equipmentStatusFactor).includes(status)) return 0;
    return pfRound(estimate * equipmentStatusFactor[status]);
}

const equipmentTypeFactor = {
    Einfach: 0,
    "Einfach Mittel": 0.0025,
    Mittel: 0.005,
    "Mittel Gehoben": 0.0075,
    Gehoben: 0.00875,
    "Gehoben Stark Gehoben": 0.00925,
    "Stark Gehoben": 0.01
};

/**
 * This function calculates the price change based on the equipment type and estimate.
 * @param {string} equipmentType - a string representing the type of equipment being used. It is used
 * to look up a factor in the equipmentTypeFactor object.
 * @param {number} estimate - The estimated price of a product or service.
 * @returns a number, which is the estimate multiplied by the factor associated with the given
 * equipment type. If the equipment type is not found in the equipmentTypeFactor object, the function
 * returns 0.
 */
export function priceChangeWithEquipmentType(equipmentType: string, estimate: number) {
    if (!Object.keys(equipmentTypeFactor).includes(equipmentType)) return 0;
    return pfRound(estimate * equipmentTypeFactor[equipmentType]);
}

/**
 * This function calculates the price change of a property based on the construction year, roof type,
 * area, and number of floors.
 *
 * we use the top most floor and the roof tilt to calculate the roof investment cost
 *
 * sources:
 * https://www.energieheld.de/dach/kosten
 * https://www.kern-haus.de/ratgeber/baulexikon/satteldach/#:~:text=Die%20Dachneigung%20kann%20von%20unterschiedlichen,38%20und%2045%20Grad%20Neigung.
 * https://www.mohr-dachbaustoffe.de/service/dachflaechenberechnung/
 *
 * @param {number} yearToday - The year in which the building was constructed.
 * @param {number} dach - The "dach" parameter represents the year in which the roof of a building was
 * constructed.
 * @param {number} area - The area of the building in square meters.
 * @param {number} floors - The parameter "floors" represents the number of floors in the building. It
 * is used to calculate the roof area if it is provided and greater than 0.
 * @returns a number that represents the change in price based on the difference between the
 * construction year and the year of the roof renovation. If the difference is greater than 25 years,
 * the function returns the negative value of the mean roof investment cost. Otherwise, it returns the
 * negative value of the product of the difference between the years and the roof investment cost
 * divided by the years of depreciation (Abschreibung der Investment Kosten).
 */
export function priceChangeWithDach(yearToday: number, dach: number, area: number, floors: number) {
    if (dach > yearToday) return 0;

    // constants to calculate the roof investment cost
    const roofFactor = 1.325; // Germany mean roof tilt is 38-45 degrees resulting in a factor of 1.325
    const meanRoofSqMPrice = 195; // Germany mean roof price is between 100-375 EUR per square meter, which is high variance. We use the mean value of 195 EUR per square meter
    const yearsDepreciation = 25;
    const meanRoofInvestmentCost = 30000; //

    // calculate the roof investment cost
    const diff = yearToday - dach;
    const roofArea = !!floors && floors > 0 ? roofFactor * (area / floors) : undefined;
    const roofInvestmentCost = !!roofArea ? meanRoofSqMPrice * roofArea : meanRoofInvestmentCost;

    if (diff > 25) return -pfRound(roofInvestmentCost);
    else return -pfRound((diff / yearsDepreciation) * roofInvestmentCost);
}

/**
 * The function calculates the cost of a fassade investment based on the construction year, fassade
 * condition, and area of a property.
 * 
 * This is tricky, as living area can be split up in a lot of ways, meaning the actual area of the walls is estimated very roughly.
 * We assume a 2.5m height of the walls per floor. We will also assume a side of the wall to be the sqrt of the area. We of course have 4 falls
 * calculate the fassade investment cost.
 * https://www.energie-fachberater.de/fassade/fassadensanierung/was-kostet-die-fassadensanierung.php#:~:text=Die%20Kosten%20f%C3%BCr%20eine%20Fassadensanierung,bis%2040%20Euro%20pro%20Quadratmeter.&text=F%C3%BCr%20eine%20Putzerneuerung%20gibt%20es,neuen%20Deckputz%20(Oberputz)%20aufzubringen.
    
 * 
 * @param {number} yearToday - The year in which the building was constructed.
 * @param {number} fassade - The "fassade" parameter represents the year in which the facade of the
 * building was last renovated or updated.
 * @param {number} area - The living area of the property in square meters.
 * @returns A negative number representing the estimated cost of a fassade renovation based on the
 * difference between the construction year and the year of the last fassade renovation, the area of
 * the property, and the mean price per square meter for wall painting in Germany. If the difference
 * between the years is greater than 20, the full cost of the renovation is returned. Otherwise, a
 * proportionate cost based on depreciation is returned
 */
export function priceChangeWithFassade(yearToday: number, fassade: number, area: number) {
    if (fassade > yearToday) return 0;

    const diff = yearToday - fassade;
    const wallArea = Math.sqrt(area) * 2.5 * 4;
    const meanWallSqMPrice = 30; // Germany mean wall painting price is between 20-40 EUR per square meter
    const wallInvestmentCost = meanWallSqMPrice * wallArea;

    if (diff > 20) return -pfRound(wallInvestmentCost);
    else return -pfRound((diff / 20) * wallInvestmentCost);
}

// https://www.heizung.de/ratgeber/modernisierung/heizung-erneuern-und-verbrauchskosten-senken.html
const heatingCost = {
    // https://www.heizung.de/ratgeber/diverses/ofenheizung-funktion-brennstoffe-und-kosten.html
    [EquipmentHeating.EINZELOEFEN]: 2750,
    // https://www.enpal.de/magazin/fussbodenheizung-kosten#:~:text=Fu%C3%9Fbodenheizung%20in%202023%20%2D%20das%20Wichtigste,60%20%E2%80%93%20120%20%E2%82%AC%2Fqm
    [EquipmentHeating.FUSSBODENHEIZUNG]: 90,
    // https://www.kesselheld.de/kosten-einer-zentralheizung/
    [EquipmentHeating.ZENTRALHEIZUNG]: 18000,
    [EquipmentHeating.SONSTIGE]: 5500
};

/**
 * This function calculates the change in price based on the age of a heating system and the
 * type of equipment used.
 *
 * https://www.heizung.de/ratgeber/modernisierung/heizung-erneuern-und-verbrauchskosten-senken.html
 * https://www.heizung.de/ratgeber/diverses/ofenheizung-funktion-brennstoffe-und-kosten.html
 * https://www.enpal.de/magazin/fussbodenheizung-kosten#:~:text=Fu%C3%9Fbodenheizung%20in%202023%20%2D%20das%20Wichtigste,60%20%E2%80%93%20120%20%E2%82%AC%2Fqm
 * https://www.kesselheld.de/kosten-einer-zentralheizung/
 *
 * @param {number} yearToday - The current year (number).
 * @param {number} heizung - The year in which the heating system was installed or last replaced.
 * @param {EquipmentHeating} heatingType - The type of heating equipment being used, which is an enum
 * value of EquipmentHeating.
 * @param {number} [area=100] - The area parameter represents the area (in square meters) of the
 * property that needs to be heated. It is used to calculate the total cost of installing a new heating
 * system, specifically for the case of underfloor heating (FUSSBODENHEIZUNG).
 * @returns a number which represents the change in price due to the age of the heating system. If the
 * heating system is newer than the current year, the function returns 0. If the heating system is
 * older than 25 years, the function returns the negative value of the investment cost of the heating
 * system. Otherwise, the function returns a negative value that is proportional to the age of the
 * heating
 */
export function priceChangeWithHeizung(yearToday: number, heizung: number, heatingType: EquipmentHeating, area: number = 100) {
    if (heizung > yearToday) return 0;

    const diff = yearToday - heizung;
    const heatingInvestmentCost = heatingType === EquipmentHeating.FUSSBODENHEIZUNG ? heatingCost[heatingType] * area : heatingCost[heatingType];
    if (diff > 25) return -pfRound(heatingInvestmentCost ?? 0);
    else return -pfRound((diff / 25) * heatingInvestmentCost ?? 0);
}

/**
 * The function calculates the potential change in the number of buyers based on the price range and
 * slider value.
 * @param {number} startPrice - The starting price of a product or service.
 * @param {number} endPrice - The ending price of the product or item being sold.
 * @param {number} sliderValue - The value of the slider, which can be 80, 90, 100, 110, or 120.
 * @returns a number that represents the potential change in the number of buyers based on the given
 * start price, end price, and slider value. The exact value returned depends on the specific case in
 * the switch statement and the calculations performed within each case.
 */
export function potentialBuyersChangeWithPrice(startPrice: number, endPrice: number, sliderValue: number) {
    const t = (startPrice - endPrice) / endPrice;
    const priceChange = endPrice / startPrice;
    const valueAt90 = 31 + (39 - 31) / (1 + Math.exp(-4 * t));
    const valueAt100 = 21 + (29 - 21) / (1 + Math.exp(-5.5452 * t));
    const valueAt110 = 4 + 3 / (1 + Math.exp(-1.148 * t));
    if (sliderValue >= 90 && sliderValue < 100) {
        return priceChange > 2 ? 0 : linearInterpolation(90, valueAt90, 100, valueAt100, sliderValue);
    }
    if (sliderValue > 100 && sliderValue < 110) {
        return priceChange > 2 ? 0 : linearInterpolation(90, valueAt90, 100, valueAt100, sliderValue);
    }
    if (sliderValue > 100 && sliderValue < 110) {
        // return priceChange > 2 ? 0 : linearInterpolation(100, valueAt100, 110, valueAt110, sliderValue)
        return priceChange > 2 ? 0 : exponentialInterpolation(100, 110, valueAt100, valueAt110, sliderValue);
    } else {
        switch (sliderValue) {
            case 80:
                return priceChange > 2 ? 0 : 43 + 10 / (1 + Math.exp(-2.197 * t));
            case 90:
                return priceChange > 2 ? 0 : 31 + (39 - 31) / (1 + Math.exp(-4 * t));
            case 100:
                return priceChange > 2 ? 0 : 21 + (29 - 21) / (1 + Math.exp(-5.5452 * t));
            case 110:
                return priceChange > 2 ? 0 : 4 + 3 / (1 + Math.exp(-1.148 * t));
            case 120:
                return priceChange > 2 ? 0 : 1 + 1 / (1 + Math.exp(-0.693 * t));
        }
    }
}

export function exponentialInterpolation(x_lower, y_lower, x_upper, y_upper, x) {
    const t = (x - x_lower) / (x_upper - x_lower);
    return y_lower * (y_upper / y_lower) ** t;
}

export default exponentialInterpolation;

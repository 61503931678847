import React from "react";
import InfoCard from "../info-cards/info-card";
import dynamic from "next/dynamic";
import PageIntro from "../page-intro";

const loadConfig = {
    ssr: false
};

const PageSquareMeter = dynamic(() => import("../page-square-meters"), { ...loadConfig });
const PageGraphs = dynamic(() => import("../page-graphs"), { ...loadConfig });
const PageContactDetails = dynamic(() => import("../page-contact-details"), { ...loadConfig });
const PageProvision = dynamic(() => import("../page-real-estate-provision"), { ...loadConfig });
const PageAnimatedPrice = dynamic(() => import("../page-animated-price"), { ...loadConfig });
const PageKapitalAnleger = dynamic(() => import("../page-kapital-anleger"), { ...loadConfig });
const PageImpairment = dynamic(() => import("../page-impairment"), { ...loadConfig });
const PageLocationFirst = dynamic(() => import("../page-location-first"), { ...loadConfig });
const PageLocationSecond = dynamic(() => import("../page-location-second"), { ...loadConfig });
const PageMarketComparison = dynamic(() => import("../page-market-comparison"), { ...loadConfig });
const PagePropertyType = dynamic(() => import("../page-property-type"), { ...loadConfig });
const PageSalesStrategy = dynamic(() => import("../page-sales-strategy"), { ...loadConfig });
const PageInterestRate = dynamic(() => import("../page-interest-rate"), { ...loadConfig });

// house specific
const PageBasement = dynamic(() => import("../pages-haus/page-basement"), { ...loadConfig });
const PageEquipment = dynamic(() => import("../pages-haus/page-equipment"), { ...loadConfig });
const PageBauJahr = dynamic(() => import("../pages-haus/page-baujahr"), { ...loadConfig });
const PageGarden = dynamic(() => import("../pages-haus/page-garden"), { ...loadConfig });
const PageRealEstateInformation = dynamic(() => import("../pages-haus/page-real-estate-information"), { ...loadConfig });
const PageRealEstateType1 = dynamic(() => import("../pages-haus/page-real-estate-type-1"), { ...loadConfig });
const PageRealEstateType2 = dynamic(() => import("../pages-haus/page-real-estate-type-2"), { ...loadConfig });
const PageRealEstateUse = dynamic(() => import("../pages-haus/page-real-estate-use"), { ...loadConfig });
const PageRooms = dynamic(() => import("../pages-haus/page-rooms"), { ...loadConfig });
const PageParking = dynamic(() => import("../pages-haus/page-parking"), { ...loadConfig });
const PageBalcony = dynamic(() => import("../pages-haus/page-balcony"), { ...loadConfig });
const PageMaintenance = dynamic(() => import("../pages-haus/page-maintenance"), { ...loadConfig });
// mfh specific
const PageErtragswert = dynamic(() => import("../pages-mehrfamilienhaus/page-ertragswert"), { ...loadConfig });
const PageDach = dynamic(() => import("../pages-mehrfamilienhaus/page-dach"), { ...loadConfig });
const PageFassade = dynamic(() => import("../pages-mehrfamilienhaus/page-fassade"), { ...loadConfig });
const PageFloors = dynamic(() => import("../pages-mehrfamilienhaus/page-floors"), { ...loadConfig });
const PageHeizung = dynamic(() => import("../pages-mehrfamilienhaus/page-heizung"), { ...loadConfig });
const PageImmobiliennutzung = dynamic(() => import("../pages-mehrfamilienhaus/page-immobiliennutzung"), { ...loadConfig });
const PageSpace = dynamic(() => import("../pages-mehrfamilienhaus/page-space"), { ...loadConfig });
// etw specific
const PageRoomsApartment = dynamic(() => import("../pages-wohnung/page-rooms-apartment"), { ...loadConfig });
const PageWohnung = dynamic(() => import("../pages-wohnung/page-wohnung"), { ...loadConfig });
const PageConstruction = dynamic(() => import("../pages-wohnung/page-construction"), { ...loadConfig });

const Success = dynamic(() => import("../success"), { ...loadConfig });

const slideConfigsIntro = [
    { component: PageIntro },
    { component: InfoCard, infoVariant: 1 },
    { component: PageLocationFirst },
    { component: PageSquareMeter },
    { component: PageAnimatedPrice },
    { component: InfoCard, infoVariant: 2 }
];

export const slideConfigsEFH = [
    { component: PageRealEstateInformation },
    { component: PageRealEstateType1 },
    { component: PageRooms },
    { component: PageBauJahr },
    { component: PageMaintenance },
    { component: PageEquipment },
    { component: PageBasement },
    { component: PageParking },
    { component: PageBalcony },
    { component: PageGarden },
    { component: PageRealEstateUse },
    { component: PageImpairment },
    { component: InfoCard, infoVariant: 3 },
    { component: PageMarketComparison },
    { component: PageKapitalAnleger },
    { component: InfoCard, infoVariant: 4 },
    { component: PageInterestRate },
    { component: PageSalesStrategy },
    { component: PageProvision },
    { component: PageContactDetails },
    { component: Success }
];

const slideConfigsETW = [
    { component: PageConstruction },
    { component: PageMaintenance },
    { component: PageEquipment },
    { component: PageWohnung },
    { component: PageRoomsApartment },
    { component: PageBasement },
    { component: PageParking },
    { component: PageBalcony },
    { component: PageGarden },
    { component: PageRealEstateUse },
    { component: PageImpairment },
    { component: InfoCard, infoVariant: 3 },
    { component: PageMarketComparison },
    { component: PageKapitalAnleger },
    { component: InfoCard, infoVariant: 4 },
    { component: PageInterestRate },
    { component: PageSalesStrategy },
    { component: PageProvision },
    { component: PageContactDetails },
    { component: Success }
];

const slideConfigsMFH = [
    { component: PageSpace },
    { component: PageFloors },
    { component: PageDach },
    { component: PageFassade },
    { component: PageHeizung },
    { component: PageMaintenance },
    { component: PageEquipment },
    { component: PageBasement },
    { component: PageParking },
    { component: PageBalcony },
    { component: PageGarden },
    { component: PageImmobiliennutzung },
    { component: PageImpairment },
    { component: InfoCard, infoVariant: 3 },
    { component: PageMarketComparison },
    { component: PageKapitalAnleger },
    { component: InfoCard, infoVariant: 4 },
    { component: PageInterestRate },
    { component: PageSalesStrategy },
    { component: PageProvision },
    { component: PageContactDetails },
    { component: Success }
];

export const numberOfEFHSlides = slideConfigsEFH?.length;
export const numberOfETWSlides = slideConfigsETW?.length;
export const numberOfMFHSlides = slideConfigsMFH?.length;
export const numberOfIntroSlides = slideConfigsIntro?.length;

export const GENERAL_STEPS = numberOfIntroSlides - 1;
export const EXTRA_INFO_CARDS = 3;
export const SKIP_STEP_HAUS = GENERAL_STEPS + numberOfEFHSlides - 7; // is the slide with potential Buyers. If "kapitalAnleger" is false jump over "Ertragsrechner"-Slide
export const SKIP_STEP_WOHNUNG = GENERAL_STEPS + numberOfETWSlides - 7;
export const SKIP_STEP_MEHRFAMILIENHAUS = GENERAL_STEPS + numberOfMFHSlides - 7;

const generateSlideSequence = configs => {
    let activeStep = configs === slideConfigsIntro ? 0 : GENERAL_STEPS + 1;
    return configs.map(config => {
        const slide = {
            ...config,
            activeStep: activeStep++
        };
        return slide;
    });
};

export function createStepContent(handleNext, handleBackward, initialState, documentId, activeStep, slideConfigs) {
    const slides = generateSlideSequence(slideConfigs);
    const currentSlide = slides.find(slide => slide.activeStep === activeStep);
    return currentSlide ? (
        <currentSlide.component
            handleNext={handleNext}
            handleBackward={handleBackward}
            initialState={initialState}
            id={documentId}
            infoVariant={currentSlide?.infoVariant}
        />
    ) : (
        "Page not found"
    );
}
export function getStepContentHaus(handleNext, handleBackward, initialState, documentId, activeStep) {
    return createStepContent(handleNext, handleBackward, initialState, documentId, activeStep, slideConfigsEFH);
}
export function getStepContentMehrfamilienhaus(handleNext, handleBackward, initialState, documentId, activeStep) {
    return createStepContent(handleNext, handleBackward, initialState, documentId, activeStep, slideConfigsMFH);
}
export function getStepContentWohnung(handleNext, handleBackward, initialState, documentId, activeStep) {
    return createStepContent(handleNext, handleBackward, initialState, documentId, activeStep, slideConfigsETW);
}
export function getStepContentIntro(handleNext, handleBackward, initialState, documentId, activeStep) {
    return createStepContent(handleNext, handleBackward, initialState, documentId, activeStep, slideConfigsIntro);
}

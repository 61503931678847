import React from "react";
import { makeStyles, Paper, Grid } from "@material-ui/core";
import { PaperProps } from "@material-ui/core/Paper";

export const backgroundDefaultPadding = 20;
export const backgroundDefaultMargin = 20;

const useStyles = makeStyles(muiTheme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        textAlign: "center",
        boxShadow: "0 0 2rem 0 rgba(136, 152, 170,.15)",
        padding: backgroundDefaultPadding,
        marginTop: backgroundDefaultMargin,
        marginBottom: backgroundDefaultMargin,
        maxWidth: "100%",
        width: "100%",
        overflow: "visible",
        // minHeight: 464,
        [muiTheme.breakpoints.down("xs")]: {
            margin: 0,
            marginTop: 0,
            marginBottom: 0,
            minHeight: "inherit"
        },
        "@media (max-width: 1024px)": {
            "@media (orientation:landscape)": { paddingTop: 10, paddingBottom: 10 }
        }
    }
}));

export interface DesignBackgroundCard {
    backgroundColor: string;
    borderColor: string;
    borderRadius: string;
    boxShadow: string;
    borderWidth: number;
}

const OnPreoPaperBackground = (props: PaperProps) => {
    const { ...other } = props;
    const classes = useStyles();
    return (
        <Grid container lg={12} style={{ display: "flex" }} justifyContent="center">
            <Grid item xs={8} md={10} style={{ maxWidth: 920 }}>
                <Paper className={`${classes.root} ${other.className} op-background`} {...other} />
            </Grid>
        </Grid>
    );
};

export default OnPreoPaperBackground;

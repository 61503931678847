import React, { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CompareArrowsOutlined, HomeOutlined, LocationOnOutlined, Timeline } from "@material-ui/icons";
import OnPreoPaperBackground from "../../layout/onpreo-paper-background";
import { useStyles } from "../../layout/styles";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
import PageButtons from "../page-buttons";
import { useAppDispatch } from "../../../../guest-app/src/store";
import { setProperty } from "src/store/slices/property.slice";
import { getRealEstateCategoryMap } from "../price-rules/rules";
import { useReduxSelector } from "src/store/selectors";
import { getRealEstate } from "src/store/selectors/real-estate.selectors";

const iconStyle = { width: 128, height: 128, color: "#fff" };

const steps = [
    { icon: <LocationOnOutlined style={iconStyle} />, name: "Lage", description: "Die Lage ist der Ausgangspunkt für die Wertermittlung Ihrer Immobilie" },
    {
        icon: <HomeOutlined style={iconStyle} />,
        name: "Immobilie",
        description: "Gemeinsam passen wir den ermittelten Durchschnittspreis schrittweise an Ihre Immobilie an"
    },
    {
        icon: <CompareArrowsOutlined style={iconStyle} />,
        name: "Marktvergleich",
        description: "Vergleichbare Immobilien liefern zusätzliche Transparenz"
    },
    {
        icon: <Timeline style={iconStyle} />,
        name: "Verkaufsstrategie",
        description: "Der richtige Startpreis ist entscheidend für einen erfolgreichen Verkauf"
    }
];

const useStyle = makeStyles(theme =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.primary.main
        },
        button: {
            padding: theme.spacing(0, 0, 4, 0)
        },
        iPad: {
            minWidth: 1200,
            "@media (max-width: 1024px)": {
                "@media (orientation:landscape)": { width: 979, minWidth: 700 },
                "@media (orientation:portrait)": { width: 700, minWidth: 700 }
            }
        }
    })
);

const InfoCard = ({ handleNext, handleBackward, infoVariant = 1 }) => {
    const classes = { ...useStyles(), ...useStyle() };

    const item = steps[infoVariant - 1];
    const dispatch = useAppDispatch();
    const state = useReduxSelector(getRealEstate);

    //(infoVariant === 1) ? dispatch(setProperty({ type: getRealEstateCategoryMap(state.category) })) : null; // works when skipStepForward is removed in price rules


    return (
        <form>
            <Fragment>
                <Grid container item justifyContent={"center"} style={{ display: "flex" }} className={classes.iPad}>
                    <PageButtons handleForward={handleNext} handleBackward={handleBackward}>
                        <OnPreoPaperBackground className={classes.paper}>
                            <Grid container item xs={12}>
                                <div className={classes.root}>
                                    <Grid container direction="column" justifyContent="center">
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            justifyContent={"center"}
                                            className={classes.grid}
                                            style={{ margin: "72px 0 96px 0" }}
                                        >
                                            <Grid item container xs={12} direction="column" justifyContent="center" alignItems={"center"}>
                                                {item.icon}
                                                <Typography style={{ fontWeight: 600, fontSize: 44, color: "#fff", margin: "24px 0" }}>
                                                    <span style={{ fontWeight: 600, color: "orange" }}>{`${infoVariant}. `}</span>
                                                    {`${item.name}`}
                                                </Typography>
                                                <Typography
                                                    align={"center"}
                                                    style={{ fontWeight: 600, fontSize: 27, color: "#fff", maxWidth: 700 }}
                                                >{`${item.description}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </OnPreoPaperBackground>
                    </PageButtons>
                </Grid>
            </Fragment>
        </form>
    );
};

export default InfoCard;

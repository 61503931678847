import React, { Fragment, useEffect, useState } from "react";
import { RealEstateProps } from "../src/functions/props-factory";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import realEstateService from "../src/store/services/real-estate";
import PriceAssistentLoader from "../src/components/price-assistent-loader";
import { setUser } from "../src/store/slices/user.slice";
import { useDispatch } from "react-redux";
// import dynamic from "next/dynamic";
// const PriceAssistentLoader = dynamic(() => import("../src/components/price-assistent-loader"));

export type IndexProps = { type: "SUCCESS"; props: RealEstateProps } | { type: "ERROR"; error: any };

const PriceFinderLoader = () => {
    return (
        <Grid container item direction="column" justifyContent={"center"} alignItems={"center"} style={{ marginTop: 48 }}>
            <Typography>Preis-Finder wird vorbereitet</Typography>
            <br />
            <CircularProgress />
        </Grid>
    );
};

const Index = () => {
    const [loading, setIsLoading] = useState(false);
    const [props, setProps] = useState(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string)
        });

        const documentId = (params as any)?.re;
        if (!props && documentId) {
            (async () => {
                setIsLoading(true);
                const response = await realEstateService.getInitialRealEstate(documentId);
                setProps(response.data.props);
                dispatch(setUser(response.data.props.user));
                setIsLoading(false);
            })();
        }
    }, []);

    return (
        <Fragment>
            {props?.type === "SUCCESS" && !loading && <PriceAssistentLoader {...props.props} />}
            {props?.type !== "SUCCESS" && !loading && (
                <Grid container item justifyContent={"center"} alignItems={"center"} style={{ marginTop: 48 }}>
                    <Typography>Sie haben nicht die nötigen Rechte, um diese Ressource zu nutzen</Typography>
                </Grid>
            )}
            {loading && <PriceFinderLoader />}
        </Fragment>
    );
};

export default Index;

const uploadPreSignedFile = async (preSigned: string, file: any, isSecure?: boolean) => {
    // needs to be FormData for AWS to work --- not done by choice
    const formData = new FormData();

    Object.entries({ preSigned, file }).forEach(([key, value]) => {
        formData.append(key, value as string);
    });

    console.log(preSigned, {
        method: "PUT",
        body: file,
        headers: {
            "x-amz-acl": isSecure ? "private" : "public-read"
        }
    });

    await fetch(preSigned, {
        method: "PUT",
        body: file,
        headers: {
            "x-amz-acl": isSecure ? "private" : "public-read"
        }
    }).catch(err => console.log(err));
};

export const imageHandler = {
    uploadPreSignedFile
};

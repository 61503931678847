import numeral from "numeral";
require("numeral/locales/de");
import { format, formatDistanceToNow } from "date-fns";
import { Address } from "@onpreo/database/src";

// ----------------------------------------------------------------------

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = ".";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    key: "Hello"
                }
            },
            de: {
                translation: {
                    key: "Hallo"
                }
            }
        },
        fallbackLng: "de",
        detection: {
            order: ["navigator", "cookie", "htmlTag"],
            caches: ["cookie", "localStorage"]
        },
        interpolation: {
            escapeValue: false
        }
    });

export function getSeparators() {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, { useGrouping: true });
    const parts = formatter.formatToParts(123456);
    let thousandSeparator = "";
    let decimalSeparator = "";

    for (let part of parts) {
        if (part.type === "group") {
            thousandSeparator = part.value;
        } else if (part.type === "decimal") {
            decimalSeparator = part.value;
        }
    }

    // special case for Swiss local to use ' instead of ´
    if (locale === "de-CH") {
        thousandSeparator = "'";
    }
    // make sure to set a default thousand separator if none is found
    thousandSeparator = thousandSeparator || ".";
    decimalSeparator = decimalSeparator || ",";

    return { thousandSeparator, decimalSeparator };
}

export function currencyCodeToSymbol(currencyCode, locale = "en") {
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0
    });

    // formatting a symbolic number to extract the currency format
    const formatted = formatter.format(0);

    // delete numeric and extra characters to get only the currency symbol
    const symbol = formatted.replace(/[\d.,\s]/g, "").trim();

    return " " + symbol;
}

export function formatCurrencyCHF(number) {
    if (!number) return "0";
    let formattedNumber = new Intl.NumberFormat("de-CH", { style: "decimal", minimumFractionDigits: 0 }).format(number);
    formattedNumber = formattedNumber.replace(/[\u2019]/g, "'"); // Replace apostrophe with single quote
    return formattedNumber;
}

export function germanToSwissNumber(germanNumber: string) {
    if (Number(germanNumber) === 0 || !germanNumber) return "0";
    const numberWithoutPoint = germanNumber.replace(/\./g, "");
    const swissNumber = numberWithoutPoint.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return swissNumber;
}

export function parseSwissNumber(swissNumberString) {
    const numberWithoutSeparators = swissNumberString.replace(/'/g, "");
    return parseInt(numberWithoutSeparators, 10);
}

export function CurrencyShort(number) {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    let formattedNumber = formatter.format(number);

    if (locale === "de-CH") {
        const parts = formatter.formatToParts(123456);
        let incorrectSeparator = "";

        for (let part of parts) {
            if (part.type === "group") {
                incorrectSeparator = part.value;
                break;
            }
        }
        const correctSeparator = "'";
        formattedNumber = formattedNumber.replace(new RegExp(incorrectSeparator, "g"), correctSeparator);
    }

    return formattedNumber;
}

export function Currency(number) {
    // return numeral(number).format(Number.isInteger(number) ? "0,0" : "0.0.00");
    return numeral(number).format("0,0.00");
}

export function Percent(number) {
    return numeral(number / 100).format("0.0%");
}

export function formatNumber(number) {
    return numeral(number).format();
}

export function ShortenNumber(number) {
    return numeral(number).format("0.00a").replace(".00", "");
}

export function Data(number) {
    return numeral(number).format("0.0 b");
}

export function formatDate(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd MMMM yyyy");
}

export function showFormatDate(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd.MM.yyyy");
}

export function DateTime(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function DateTimeSuffix(date) {
    return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function ToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function parseAddress(address: Address) {
    return `${address.street} ${address.house_number}, ${address.zip} ${address.town}, ${address.nation}`;
}

export function parseLocaleNumber(stringNumber) {
    const { thousandSeparator, decimalSeparator } = getSeparators();
    const cleanNumber = stringNumber
        .replace(new RegExp(`\\${thousandSeparator}`, "g"), "") // delete thousand separator
        .replace(new RegExp(`\\${decimalSeparator}`, "g"), "."); // replace decimal separator with dot, to get a valid number
    return parseFloat(cleanNumber);
}

export const searchParams = () => {
    if (typeof window !== "undefined") {
        return window.location.search;
        // return new Proxy(new URLSearchParams(window.location.search), {
        //     get: (searchParams, prop) => searchParams.get(prop as string)
        // });
    } else return undefined;
};

// below 2 functions formulas are from excel sheets provided by tobi
export function getInterestValue(value: number, interestRate: number, repayment: number) {
    const interestValue = (value * (interestRate / 100) + value * (repayment / 100)) / 12;
    return Math.round(interestValue);
}

// calculation logic accoring to excel sheet provided by tobi "Calculating_Annuitätendarlehen-2.xlsx"
// calculate "Zinsbelastung in 10 Jahren" in this sheet to a fixed loanAmount
// the same function also exists in apps/broker-app/src/utils/formatters.ts! please keep them in sync
export function getInterestShare(loanAmount: number, interestRate: number, repayment: number): number {
    let interestRateSum = 0;
    let repaymentShare = loanAmount * (repayment / 100);
    let interestShare = loanAmount * (interestRate / 100);
    let residualDebt = loanAmount;
    const annuität = interestShare + repaymentShare;

    for (let i = 1; i <= 10; i++) {
        residualDebt -= repaymentShare;
        interestShare = residualDebt * (interestRate / 100);
        interestRateSum += interestShare;
        repaymentShare = annuität - interestShare;
    }
    return Math.round(interestRateSum);
}

export const arrayRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export function getCardDisplay(propertyType: string, activeStep: number) {
    if (propertyType === "Haus" && activeStep === 21) return true;
    else if (propertyType === "Wohnung" && activeStep === 20) return true;
    else if (propertyType === "Mehrfamilienhaus" && activeStep === 19) return true;
    else return false;
}

export function getGridPosition(monthlyInstallmentIncrease: number) {
    const defaultGridWidth = 165;
    const defaultTotalWidth = 765;
    return defaultTotalWidth - (defaultGridWidth * monthlyInstallmentIncrease) / (100 * 2);
}

export default {
    Currency: CurrencyShort,
    Percent,
    formatNumber,
    ShortenNumber,
    Data,
    formatDate,
    DateTime,
    DateTimeSuffix,
    ToNow,
    parseLocaleNumber,
    getInterestValue,
    getInterestShare,
    arrayRange,
    getCardDisplay,
    getGridPosition
};

import { createSelector } from "reselect";
import valuation from "../../../pages/api/sprengnetter/valuation";
import ReduxState from "../models";

export const getSlideChange = (state: ReduxState) => state.slideChange;
export const getPriceChangeArea = createSelector(getSlideChange, valuation => valuation.priceChangeArea);
export const getPriceChangeByType = createSelector(getSlideChange, valuation => valuation.priceChangeTypeRealEstate);
export const getPriceChangeHouseType1 = createSelector(getSlideChange, valuation => valuation.priceChangeHouseType1);
export const getPriceChangeHouseType2 = createSelector(getSlideChange, valuation => valuation.priceChangeHouseType2);
export const getPriceChangeRooms = createSelector(getSlideChange, valuation => valuation.priceChangeRooms);
export const getPriceChangeYear = createSelector(getSlideChange, valuation => valuation.priceChangeYear);
export const getPriceChangeMaintenance = createSelector(getSlideChange, valuation => valuation.priceChangeMaintenance);
export const getPriceChangeUse = createSelector(getSlideChange, valuation => valuation.priceChangeUse);
export const getPriceChangeBasement = createSelector(getSlideChange, valuation => valuation.priceChangeBasement);
export const getPriceChangeParking = createSelector(getSlideChange, valuation => valuation.priceChangeParking);
export const getPriceChangeBalcony = createSelector(getSlideChange, valuation => valuation.priceChangeBalcony);
export const getPriceChangeGarden = createSelector(getSlideChange, valuation => valuation.priceChangeGarden);
export const getPriceChangeImpairment = createSelector(getSlideChange, valuation => valuation.priceChangeImpairment);
export const getPriceChangeMarket = createSelector(getSlideChange, valuation => valuation.priceChangeMarket);
export const getPriceChangeAnleger = createSelector(getSlideChange, valuation => valuation.priceChangeAnleger);
export const getPriceChangeStrategy = createSelector(getSlideChange, valuation => valuation.priceChangeStrategy);
export const getPriceChangeWohnung = createSelector(getSlideChange, valuation => valuation.priceChangeWohnung);
export const getPriceChangeFloors = createSelector(getSlideChange, valuation => valuation.priceChangeFloors);
export const getPriceChangeErtragswert = createSelector(getSlideChange, valuation => valuation.priceChangeErtragswert);
export const getPriceChangeDach = createSelector(getSlideChange, valuation => valuation.priceChangeDach);
export const getPriceChangeFassade = createSelector(getSlideChange, valuation => valuation.priceChangeFassade);
export const getPriceChangeHeizung = createSelector(getSlideChange, valuation => valuation.priceChangeHeizung);
export const getPriceChangeGraphs = createSelector(getSlideChange, valuation => valuation.priceChangeGraphs);
export const getPriceChangeProvision = createSelector(getSlideChange, valuation => valuation.priceChangeProvision);
export const getPriceChangeSanierung = createSelector(getSlideChange, valuation => valuation.priceChangeSanierung);
export const getPriceChangeMarketDevelopment = createSelector(getSlideChange, valuation => valuation.priceChangeMarketDevelopment);
export const getPriceChangeEquipment = createSelector(getSlideChange, valuation => valuation.priceChangeEquipment);

export const getBaseValueRennovation = createSelector(getSlideChange, valuation => valuation.priceChangeRennovation);

export const regexps = {
    email: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
    // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    emailRFC:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    url: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
    numbers: /^\d+$/,
    customDomain: /^([-a-zA-Z0-9._]+\.)?([-a-zA-Z0-9._]+\.[a-z]{2,})+\/?($|\/)([-a-zA-Z0-9_]{1,32})?$/,
    //https://designpowers.com/blog/url-best-practices
    urlPath: /[^a-z\-]+/g,
    // https://stackoverflow.com/questions/33625770/check-vat-number-for-syntactical-correctness-with-regex-possible
    vat: {
        DE: /((DE)?[0-9]{9})$/g,
        AT: /((AT)?U[0-9]{8})$/g,
        CH: /(CHE-\d{3}\.\d{3}\.\d{3}\ +(IVA|TVA|MWST))/g
    },
    dateAndHours: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})$/,
    streetAndHouse: /(.+)\s+(\d+\s*[a-zA-Z]?)/ // pattern to match the street and house number
};

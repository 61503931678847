import React, { FC } from "react";
import { Button, ButtonBase, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        icon: {
            width: 40,
            height: 40,
            color: muiTheme.palette.primary.main
        },
        div: {
            flexGrow: 1,
            overflow: "visible",
            display: "flex",
            width: "100%",
            // marginLeft: "auto",
            // marginRight: "auto",
            justifyContent: "space-around"
        }
    })
);

interface PageButtonsProps {
    children: React.ReactNode;
    handleBackward?: any;
    handleForward?: any;
    forwardDisabled?: boolean;
}

const PageButtons: FC<PageButtonsProps> = props => {
    const classes = useStyles();

    return (
        <Grid item justifyContent={"center"} direction="column" xs={12} style={{ display: "flex", width: "calc(100%+8px)" }}>
            <Grid container item xs={12} justifyContent={"center"} direction="row" style={{ width: "100%", marginTop: 16, marginBottom: 16 }}>
                {/*<Grid item xs={10} />*/}
                <Grid container xs={12} justifyContent={"center"} style={{ display: "flex" }} spacing={1}>
                    <Button color={"secondary"} variant={"contained"} onClick={props.handleBackward} style={{ padding: 0, marginRight: 4 }}>
                        <ChevronLeftIcon className={classes.icon} />
                    </Button>
                    {!props.forwardDisabled && (
                        <Button color={"secondary"} variant={"contained"} onClick={props.handleForward} style={{ padding: 0, marginLeft: 4 }}>
                            <ChevronRightIcon className={classes.icon} />
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={"center"} direction="row" style={{ width: "100%", margin: "0 15px" }}>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default PageButtons;

import { Card, Grid, Typography, useTheme } from "@material-ui/core";
import React, { FC, Fragment, useState } from "react";
import { useStyles } from "../../layout/styles";
import { useReduxSelector } from "../../store/selectors";
import { getPropertyType } from "../../store/selectors/property.selectors";
import { CurrencyShort, getCardDisplay } from "../../utils/formatters";
import AnimatedNumber from "react-animated-number";
import { useSelector } from "react-redux";
import { getAskingPrice, getCurrency, getLivingArea, getWorkspacePrimary } from "../../store/selectors/real-estate.selectors";

interface PriceCardProps {
    cardValue: number;
    activeStep?: number;
}

const PriceCard: FC<PriceCardProps> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const primaryColor = useReduxSelector(getWorkspacePrimary);
    const propertyType = useReduxSelector(getPropertyType);
    const currencySymbol = useSelector(getCurrency);
    const askingPrice = useSelector(getAskingPrice);
    const low = askingPrice?.range?.low;
    const high = askingPrice?.range?.high;
    const livingArea = useSelector(getLivingArea);
    let cardDescription = "";
    const show = getCardDisplay(propertyType, props.activeStep);

    if (propertyType === "Haus" && props.activeStep >= 20) {
        cardDescription = "Ihr Vermarktungspreis";
    } else if (props.activeStep >= 19) {
        cardDescription = "Ihr Vermarktungspreis";
    } else cardDescription = "Aktuelle Preisempfehlung";

    function formatValueFunction(n) {
        if (currencySymbol === "CHF") {
            return CurrencyShort(n) + ".- " + currencySymbol;
        } else {
            return CurrencyShort(n) + " " + currencySymbol;
        }
    }

    return (
        <Fragment>
            {/* {askingPrice?.range && show && (
                <Card
                    style={{
                        width: "63%",
                        maxWidth: 420,
                        height: 150,
                        background: primaryColor ?? "#0071E3",
                        boxShadow: theme.shadows[8],
                        color: "#FFFFFF",
                        justifyContent: "center",
                        padding: 10,
                        marginRight: 20
                    }}
                >
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant={"h2"} align="center" style={{ color: "#fff" }}>
                                Quadratmeterpreise in dieser Gegend:
                            </Typography>
                            {low !== undefined ? (
                                <Grid container direction="row" justifyContent="center" alignItems="center" item>
                                    <Grid item>
                                        <Typography variant={"h3"} style={{ color: "#fff" }}>
                                            Tief: {CurrencyShort(low / livingArea)} {currencySymbol}/m²
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"h3"} style={{ color: "#fff" }}>
                                            Hoch: {CurrencyShort(high / livingArea)} {currencySymbol}/m²
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Typography variant="body1">Sprengnetter-Daten nicht verfügbar </Typography>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            )} */}
            <Card
                style={{
                    width: "63%",
                    maxWidth: 420,
                    height: 150,
                    background: primaryColor ?? "#0071E3",
                    color: "#FFFFFF",
                    justifyContent: "center",
                    // padding: theme.spacing(2, 2, 2, 2),
                    // margin: theme.spacing(1, 0, 2, 0),
                    boxShadow: theme.shadows[8],
                    padding: 10,
                    marginBottom: 10
                }} /* className={classes.card3} */
            >
                <Grid item>
                    {!!props.cardValue && (
                        <Fragment>
                            <AnimatedNumber
                                component="h1"
                                initialValue={0}
                                value={props.cardValue}
                                style={{
                                    transition: "0.4s ease-out",
                                    color: "#fff",
                                    fontSize: 40,
                                    lineHeight: 1,
                                    textAlign: "center",
                                    width: "100%",
                                    marginBottom: 4
                                }}
                                duration={1000}
                                stepPrecision={0}
                                formatValue={n => formatValueFunction(n)}
                            />
                            {/*<Typography align={"center"} variant={"h1"} style={{ color: "#fff", fontSize: 40, lineHeight: 1 }}>*/}
                            {/*    {`${CurrencyShort(props.cardValue)} €`}*/}
                            {/*</Typography>*/}
                        </Fragment>
                    )}
                    <Typography align={"center"} variant={"h3"} style={{ color: "#fff" }}>
                        {cardDescription}
                    </Typography>
                </Grid>
            </Card>
        </Fragment>
    );
};

export default PriceCard;

import { Address } from "@onpreo/database/src/definitions/commons";
import { AddressCoordinates } from "@onpreo/database/src/definitions/real-estate";
import axios from "axios";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { searchParams } from "../../utils/formatters";

export type ValuationCategory = "ETW" | "EFH" | "MFH" | "GRD";

export interface ValuationResponse {
    value: number;
    model: string;
    min: number;
    max: number;
    score: number;
    standarderror: number;
    probability: number;
    category: ValuationCategory;
}

export enum OptionalValuationQuestions {
    CONSTRUCTION_HOUSE = "CONSTRUCTION_HOUSE",
    CONSTRUCTION_FLAT = "CONSTRUCTION_FLAT",
    CONSTRUCTION_YEAR = "CONSTRUCTION_YEAR",
    LIVING_AREA = "LIVING_AREA",
    PLOT_AREA = "PLOT_AREA",
    ROOMS = "ROOMS",
    FLOOR = "FLOOR",
    FLOOR_NUMBER = "FLOOR_NUMBER",
    ELEVATOR = "ELEVATOR",
    GARAGES = "GARAGES",
    OUTDOOR_PARKING_SPACE = "OUTDOOR_PARKING_SPACE",
    BATH_ROOM = "BATH_ROOM",
    FLOORING = "FLOORING",
    HEATING = "HEATING"
}

export interface Valuation {
    category: ValuationCategory;
    address: Address;
    coordinates?: AddressCoordinates;
    optionalFields?: Record<OptionalValuationQuestions, boolean | string | number | undefined>;
}

const getInitialRealEstate = async documentId => {
    const url = `/api/realEstate/${documentId}${searchParams()}`;
    return await onpreoClient.get(url);
};

const updateRealEstate = async (documentId, document) => {
    const url = `/api/realEstate/${documentId}${searchParams()}`;
    return await onpreoClient.put(url, { documentId, document });
};

const getSprenetterValuation = async valuation => {
    const url = `/api/sprengnetter/valuation${searchParams()}`;
    return (await onpreoClient.post<ValuationResponse>(url, valuation)).data;
};

const sendInviteLinkEmail = async (realEstateId: string, variant?: string) => {
    const url = `/api/realEstate/${realEstateId}${searchParams()}`;
    return await onpreoClient.post(url, { variant });
};

const realEstateService = {
    getInitialRealEstate,
    updateRealEstate,
    getSprenetterValuation,
    sendInviteLinkEmail
};

export default realEstateService;
